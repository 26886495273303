import React, { useContext, useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoClose } from "react-icons/io5";
import CartContext from "../../context/CartContext";
import PopupContext from "../../context/PopupContext";
import { StoreContext } from "../../context/StoreContext";
import { trimAndLowerCase } from "../../util/trimAndLowerCase";
import BottomPopup from "../popup/BottomPopup";
import { useLocation } from "react-router-dom";
import { useMatchMedia } from "../../util/useMatchMedia";
import {decodeHTMLEntities} from "../../util/validate";

const Menu = () => {
  const location = useLocation();
  const isMobile = useMatchMedia("(max-width: 576px)", true);
  const isTab = useMatchMedia("(max-width: 768px)", true);
  const cartCtx = useContext(CartContext);
  const popupCtx = useContext(PopupContext);
  const { data } = useContext(StoreContext);
  const productsList = data.product_master;
  const storeDetails = data.store_master;
  let listOfCategories = productsList.map(
    (item) => decodeHTMLEntities(item.categoryname) || "Others"
  );
  // sort and remove duplicates
  listOfCategories = listOfCategories
    .sort()
    .filter((v, i) => listOfCategories.indexOf(v) === i);

  const scrollCategory = (id) => {
    popupCtx.closeMenuPopup();

    var element = document.getElementById(id);
    var sticky = document.getElementsByClassName("search-fixed");
    var headerOffset = isMobile ? (sticky.length > 0 ? 65 : -65) : 108;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    setTimeout(() => {
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }, 50);
  };

  window.onscroll = function () {
    const sections = document.querySelectorAll(".category-wrap");
    const navLinks = document.querySelectorAll(".menu-li");
    sections.forEach((sec) => {
      let top = window.scrollY;
      let offset = sec.offsetTop - 110;
      let height = sec.offsetHeight;
      let id = sec.getAttribute("id");
      if (top >= offset && top < offset + height) {
        navLinks.forEach((link) => {
          link.classList.remove("active");
          link.style.color = "";
          let currentLink = document.querySelector("." + id);
          currentLink.classList.add("active");
          currentLink.style.color = storeDetails[0].primarycolorcode;
        });
      }
    });
  };

  useEffect(() => {
    if (
      popupCtx.isShowMenu &&
      window.location.hash !== popupCtx.openPopupHashId
    ) {
      popupCtx.closeMenuPopup();
    }
  }, [location, popupCtx]);

  return (
    <div className="nav-menu-wrapper">
      {isTab && (
        <div
          className={`menu-view-icon ${
            cartCtx.items.length > 0 ? "bottom-up" : ""
          }`}
          onClick={() => popupCtx.openMenuPopup()}
          style={{ backgroundColor: storeDetails[0].primarycolorcode }}
        >
          <GiHamburgerMenu className="burger" />
          <div>Menu</div>
        </div>
      )}

      {!isTab && (
        <div className="menu-inner">
          <span className="menu-head">Menu</span>
          <ul className="menu-list">
            {listOfCategories.map((cat, index) => (
              <li
                href={`#${cat}`}
                key={index}
                className={`menu-li ${trimAndLowerCase(cat)}`}
                onClick={() => scrollCategory(trimAndLowerCase(cat))}
              >
                <span
                  className="bar"
                  style={{ borderColor: storeDetails[0].primarycolorcode }}
                ></span>
                {cat}
              </li>
            ))}
          </ul>
        </div>
      )}

      {popupCtx.isShowMenu && (
        <BottomPopup isMenuPoup={true}>
          <div className="menu-popup">
            <div
              className={`menu-list-wrapper ${
                listOfCategories.length > 5 ? "fade" : ""
              } `}
            >
              <ul className="menu-list">
                {listOfCategories.map((cat, index) => (
                  <li
                    key={index}
                    onClick={() => scrollCategory(trimAndLowerCase(cat))}
                  >
                    {cat}
                  </li>
                ))}
              </ul>
            </div>
            <IoClose
              className="menu-close-icon"
              onClick={() => popupCtx.closeMenuPopup()}
            />
          </div>
        </BottomPopup>
      )}
    </div>
  );
};

export default Menu;
