import React, { useContext, useEffect, useState } from "react";
import { FiArrowLeft } from "react-icons/fi";
import CartContext from "../context/CartContext";
import UserContext from "../context/UserContext";
import UserInfo from "../components/order/UserInfo";
import BillDetails from "../components/order/BillDetails";
import CartItem from "../components/order/CartItem";
import OrderSuccessPopup from "../components/popup/OrderSuccessPopup";
import ErrorAlert from "../components/popup/ErrorAlert";
import { useLocation } from "react-router-dom";
import ConfirmButton from "../components/order/ConfirmButton";
import QrAndTerm from "../components/order/QrAndTerm";

const OrderPage = () => {
  const location = useLocation();
  const cartCtx = useContext(CartContext);
  const userCtx = useContext(UserContext);
  const [popup, setPopup] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: "",
  });

  const goToHome = () => {
    if (
      cartCtx.items.length === 0 ||
      !userCtx.userInfo.name ||
      !userCtx.userInfo.location
    ) {
      userCtx.goToHomePage();
    }
  };

  const closeAlertPopup = () => {
    setError({
      status: false,
      message: "",
    });
  };

  useEffect(() => {
    document.body.classList.remove("search-fixed");
    goToHome();
  });

  useEffect(() => {
    if (
      !userCtx.isHomePage &&
      !userCtx.isContactPopupShow &&
      window.location.hash !== userCtx.orderHasId
    ) {
      userCtx.goToHomePage();
    }
  }, [location]);
  return (
    <>
      {error.status && (
        <ErrorAlert message={error.message}>
          <div className="icon" onClick={closeAlertPopup}>
            Close
          </div>
        </ErrorAlert>
      )}
      <div className="order-screen">
        <div className="header-title">
          <FiArrowLeft
            className="order-arw"
            onClick={() => userCtx.goToHomePage()}
          />
          <div className="bottom-bar">
            <div>My Order</div>
          </div>
        </div>
        <div className="order-container">
          <div className="oreder-main-box">
            <div className="left-box">
              <UserInfo />
              <CartItem />
            </div>
            <div className="right-box">
              <BillDetails />
              <ConfirmButton setPopup={setPopup} setError={setError}/>
              <QrAndTerm/>
            </div>
          </div>
        </div>

        <div className="pay-button-wrap">
          <ConfirmButton setPopup={setPopup} setError={setError}/>
        </div>
      </div>
      {popup && <OrderSuccessPopup />}
    </>
  );
};

export default OrderPage;
