import React, { useContext } from "react";
import UserForm from "../form/UserForm";
import { IoCloseCircle } from "react-icons/io5";
import BottomPopup from "./BottomPopup";
import UserContext from "../../context/UserContext";

const ContactPopup = ({ title }) => {
  const userCtx = useContext(UserContext);
  return (
    <BottomPopup requirePopup={true}>
      <div className="form-popup">
        <IoCloseCircle
          className="close-top"
          onClick={() => {
            userCtx.closePopup();
          }}
        />
        <UserForm title={title} />
      </div>
    </BottomPopup>
  );
};

export default ContactPopup;
