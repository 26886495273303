import React, { useContext, useState } from "react";
import { StoreContext } from "../../context/StoreContext";
import UserContext from "../../context/UserContext";
import BottomPopup from "./BottomPopup";
import { IoClose } from "react-icons/io5";
import { MdOutlineMyLocation } from "react-icons/md";
import { IoCloseCircle } from "react-icons/io5";
import { useMatchMedia } from "../../util/useMatchMedia";

const LocationPopup = () => {
  const isMobile = useMatchMedia("(max-width: 576px)", true);
  const userCtx = useContext(UserContext);
  const { data } = useContext(StoreContext);
  const storeDetails = data.store_master;
  const [value, setValue] = useState("");

  const handleSelectCity = (city) => {
    setValue(city);
    userCtx.updateLocation(city);
    setValue("");
  };
  return (
    <BottomPopup requirePopup={true}>
      <div className="loc-popup">
        <h2>Select your location</h2>
        <div className="loc-search">
          <MdOutlineMyLocation
            className="loc-icon"
            style={{ color: storeDetails[0].primarycolorcode }}
          />
          <input
            type="text"
            placeholder="Search city..."
            value={value}
            onChange={(e) => setValue(e.target.value)}
            style={{ color: storeDetails[0].primarycolorcode }}
          />
          {value && (
            <IoCloseCircle
              className="loc-search-close-icon"
              onClick={() => setValue("")}
            />
          )}
        </div>
        {storeDetails.map((store) => (
          <div className="loc-dropdown" key={store.id}>
            {store.location
              .filter((item) => {
                const searchValue = value.toLocaleLowerCase();
                const cityName = item.city.toLocaleLowerCase();
                return (
                  cityName.startsWith(searchValue) && cityName !== searchValue
                );
              })
              .map((loc) => (
                <div
                  className="loc-list"
                  key={loc.id}
                  onClick={() => handleSelectCity(loc.city)}
                >
                  {loc.city}
                </div>
              ))}
          </div>
        ))}
        {isMobile ? (
          <IoClose
            className="loc-close-icon"
            onClick={() => userCtx.closePopup()}
          />
        ) : (
          <IoCloseCircle
            className="close-top"
            onClick={() => userCtx.closePopup()}
          />
        )}
      </div>
    </BottomPopup>
  );
};

export default LocationPopup;
