import { useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Favicon from "./components/favicon/Favicon";
import Loader from "./components/loader/Loader";
import ErrorAlert from "./components/popup/ErrorAlert";
import CartContext from "./context/CartContext";
import { StoreContext } from "./context/StoreContext";
import UserContext from "./context/UserContext";
import HomePage from "./pages/HomePage";
import OrderPage from "./pages/OrderPage";

function App() {
  const storeCtx = useContext(StoreContext);
  const cartCtx = useContext(CartContext);
  const userCtx = useContext(UserContext);

  window.onbeforeunload = function (e) {
    e = e || window.event;

    if (cartCtx.items.length !== 0) {
      return "leave?";
    }
  };

  if (storeCtx.loading) return <Loader />;
  if (storeCtx.error) return <ErrorAlert message={storeCtx.error} />;

  return (
    <>
      <Favicon />
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={userCtx.isHomePage ? <HomePage /> : <OrderPage />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
