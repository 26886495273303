import React, { useContext } from "react";
import AddButton from "./AddButton";
import Price from "./Price.jsx";
import CartContext from "../../context/CartContext";
import PopupContext from "../../context/PopupContext";
import SearchContext from "../../context/SearchContext";

const ProductItem = ({ product }) => {
  const cartCtx = useContext(CartContext);
  const searchCtx = useContext(SearchContext);
  const popupCtx = useContext(PopupContext);
  const handleAddItem = () => {
    cartCtx.addItem(product);
  };

  const handleRemoveItem = () => {
    cartCtx.removeItem(product.id);
  };

  return (
    <>
      <div className="product-item" key={product.id}>
        <div className="p-left">
          <div className="p-name"><div dangerouslySetInnerHTML={{__html: product.productname}} /></div>
          <Price
            spacialPrice={product.specialprice}
            finalPrice={product.price}
          />
          <span className="sku">#{product.code}</span>
        </div>
        <div className={`p-right ${product.image ? "" : "without-img"}`}>
          {product.image && (
            <div className="img-box">
              <img
                src={product.image}
                alt={product.productname}
                onClick={() => {!searchCtx.isSearchEnabel && popupCtx.openProductPopup(product)}}
              />
            </div>
          )}
          <AddButton
            qty={product.qty}
            add={handleAddItem}
            remove={handleRemoveItem}
          />
        </div>
      </div>
      
    </>
  );
};

export default ProductItem;
