import React, { createContext, useContext, useEffect, useReducer } from "react";
import { StoreContext } from "./StoreContext";

const localData = JSON.parse(localStorage.getItem("cartItem"));

const CartContext = createContext({
  items: [],
  addItem: (item) => {},
  removeItem: (id) => {},
  clearItem: () => {},
});
const initialState = {
  items: localData || [],
};

const CartReducer = (state, action) => {
  if (action.type === "ADD_ITEM") {
    const existingCartIndex = state.items.findIndex(
      (item) => item.id === action.item.id
    );
    const updatedItems = [...state.items];
    if (existingCartIndex > -1) {
      const existingItem = state.items[existingCartIndex];
      const updatedItem = {
        ...existingItem,
        qty: existingItem.qty + 1,
      };
      updatedItems[existingCartIndex] = updatedItem;
    } else {
      updatedItems.push({ ...action.item, qty: 1 });
    }

    return { ...state, items: updatedItems };
  }
  if (action.type === "REMOVE_ITEM") {
    const existingCartIndex = state.items.findIndex(
      (item) => item.id === action.id
    );
    const existingCartItem = state.items[existingCartIndex];
    const updatedItems = [...state.items];
    if (existingCartItem.qty === 1) {
      updatedItems.splice(existingCartIndex, 1);
    } else {
      const existingItem = state.items[existingCartIndex];
      const updatedItem = {
        ...existingItem,
        qty: existingItem.qty - 1,
      };
      updatedItems[existingCartIndex] = updatedItem;
    }
    return { ...state, items: updatedItems };
  }
  if (action.type === "CLEAR_ITEM") {
    const clearItem = [];
    return { ...state, items: clearItem };
  }
  return state;
};

export const CartContextProvider = ({ children }) => {
  const { data, isLocalClear } = useContext(StoreContext);

  const [cart, dispatchCartAction] = useReducer(CartReducer, initialState);

  const productList = data.product_master;

  const addItem = (item) => {
    dispatchCartAction({ type: "ADD_ITEM", item });
  };

  const removeItem = (id) => {
    dispatchCartAction({ type: "REMOVE_ITEM", id });
  };

  const clearItem = (id) => {
    dispatchCartAction({ type: "CLEAR_ITEM", id });
  };

  useEffect(() => {
    if (isLocalClear) {
      dispatchCartAction({ type: "CLEAR_ITEM" });
    }
  }, [isLocalClear]);

  const cartContext = {
    items: cart.items,
    addItem,
    removeItem,
    clearItem,
  };

  if (productList) {
    for (var i = 0; i < productList.length; i++) {
      let list = productList[i];
      list.qty = 0;
      let cartItem = cart.items;
      if (cartItem.length > 0) {
        for (var k = 0; k < cartItem.length; k++) {
          let cart = cartItem[k];
          if (list.id === cart.id) {
            list.qty = cart.qty;
          }
        }
      }
    }
  }
  localStorage.setItem("cartItem", JSON.stringify(cart.items) || []);

  return (
    <CartContext.Provider value={cartContext}>{children}</CartContext.Provider>
  );
};

export default CartContext;
