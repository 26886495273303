import React, { useContext } from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { StoreContext } from "../../context/StoreContext";

const Favicon = () => {
  const { data } = useContext(StoreContext);
  const storeDetails = data.store_master;
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{storeDetails[0].storename}</title>
        <link rel="icon" type="image/png" href={storeDetails[0].faviicon} />
      </Helmet>
    </HelmetProvider>
  );
};

export default Favicon;
