import React, { useContext, useEffect, useState } from "react";
import { MdLocationOn } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import { StoreContext } from "../../context/StoreContext";
import UserContext from "../../context/UserContext";
import { useLocation } from "react-router-dom";
import { useMatchMedia } from "../../util/useMatchMedia";

const Location = () => {
  const isMobile = useMatchMedia("(max-width: 576px)", true);
  const location = useLocation();
  const userCtx = useContext(UserContext);
  const { data } = useContext(StoreContext);
  const storeDetails = data.store_master;
  

  useEffect(() => {
    if (
      userCtx.isLoactionPopupShow &&
      window.location.hash !== userCtx.openPopupHashId
    ) {
      userCtx.closePopup();
    }
  }, [location, userCtx]);

  return (
    <>
      {isMobile && (
        <div className="location">
          <div
            className="location-btn"
            onClick={() => userCtx.ShowLocationPopup()}
          >
            <MdLocationOn className="location-icon" />
            <span>{userCtx.userInfo.location || "Select Location"}</span>
            <MdKeyboardArrowDown className="arrow-icon" />
          </div>
        </div>
      )}
      {!isMobile && (
        <div className="location">
          <MdLocationOn className="location-icon" />
          <div className="loc-label">Your Location:</div>
          <div className="addr">
            {userCtx.userInfo.location || "Select Location"}
          </div>
          <span
            className="change-loc"
            onClick={() => userCtx.ShowLocationPopup()}
            style={{ color: storeDetails[0].primarycolorcode }}
          >
            Change
          </span>
        </div>
      )}
      
    </>
  );
};

export default Location;
