import { createContext } from "react";
import { API_PRODUCT_AND_STORE } from "../api/api";
import useFetch from "../hooks/useFetch";
import { CartContextProvider } from "./CartContext";
import { PopupContextProvider } from "./PopupContext";
import { SearchContextProvider } from "./SearchContext";
import { UserContextProvider } from "./UserContext";

export const StoreContext = createContext();

const StoreContextProvider = ({ children }) => {
  const { data, loading, error, isLocalClear } = useFetch(API_PRODUCT_AND_STORE);

  return (
    <StoreContext.Provider value={{ data, loading, error, isLocalClear }}>
      <CartContextProvider>
        <SearchContextProvider>
          <UserContextProvider>
            <PopupContextProvider>{children}</PopupContextProvider>
          </UserContextProvider>
        </SearchContextProvider>
      </CartContextProvider>
    </StoreContext.Provider>
  );
};

export default StoreContextProvider;
