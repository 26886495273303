import React, { useContext } from "react";
import { GoCheckCircle } from "react-icons/go";
import CartContext from "../../context/CartContext";
import { StoreContext } from "../../context/StoreContext";

const OrderSuccessPopup = () => {
  const cartCtx = useContext(CartContext);
  const { data } = useContext(StoreContext);
  const storeDetails = data.store_master;
  return (
    <div className="success-popup">
      <div className="overlay"></div>
      <div className="popup-box">
        <div className="tic-box">
          <GoCheckCircle className="tic-icon" />
          <span>Success</span>
        </div>
        <div className="content-box">
          <div className="msg">
            Your order has been successfully placed.
            {storeDetails[0].paymentQR && " Pay using QR code"}
          </div>
          {storeDetails[0].paymentQR && (
            <img src={storeDetails[0].paymentQR} alt="QRcode" />
          )}
          <button onClick={() => cartCtx.clearItem()}>Return Home</button>
        </div>
      </div>
    </div>
  );
};

export default OrderSuccessPopup;
