import React, { useContext, useEffect } from "react";
import CartContext from "../../context/CartContext";
import PopupContext from "../../context/PopupContext";
import { IoCloseCircle } from "react-icons/io5";
import BottomPopup from "./BottomPopup";
import AddButton from "../product/AddButton";
import Price from "../product/Price";
import { useLocation } from "react-router-dom";

const ProductPopup = () => {
  const location = useLocation();
  const popupCtx = useContext(PopupContext);
  const product = popupCtx.product;
  const cartCtx = useContext(CartContext);
  const handleAddItem = () => {
    cartCtx.addItem(product);
  };

  const handleRemoveItem = () => {
    cartCtx.removeItem(product.id);
  };

  useEffect(() => {
    if (
      popupCtx.isShowProduct &&
      window.location.hash !== popupCtx.openPopupHashId
    ) {
      popupCtx.closeProductPopup();
    }
  }, [location, popupCtx]);

  return (
    <>
      {popupCtx.isShowProduct && (
        <BottomPopup isProductPopup={true}>
          <div className="product-detail">
            <IoCloseCircle
              className="close-top"
              onClick={() => popupCtx.closeProductPopup()}
            />
            <div className="img">
              <img src={product.image} alt={product.productname} />
            </div>
            <div className="content">
              <div className="top">
                <div className="p-name"><div dangerouslySetInnerHTML={{__html: product.productname}} /></div>
                <span className="sku">#{product.code}</span>
              </div>
              <div className="bot">
                <Price
                  spacialPrice={product.specialprice}
                  finalPrice={product.price}
                />
                <AddButton
                  qty={product.qty}
                  add={handleAddItem}
                  remove={handleRemoveItem}
                />
              </div>
            </div>
          </div>
        </BottomPopup>
      )}
    </>
  );
};

export default ProductPopup;
