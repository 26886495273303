import axios from "axios";
import { useEffect, useReducer } from "react";
import versionHistort from "../versionHistory.json";
const localAppVersion = JSON.parse(localStorage.getItem("appVersion"));

const ACTIONS = {
  API_REQUEST: "api_request",
  FETCH_DATA: "fetch-data",
  ERROR: "error",
  CLEAR_STORAGE: "clear_storage",
};

const initialState = {
  data: [],
  loading: true,
  error: null,
  isLocalClear: false,
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.API_REQUEST:
      return { ...state, data: [], loading: true };
    case ACTIONS.FETCH_DATA:
      return { ...state, data: payload, loading: false };
    case ACTIONS.ERROR:
      return { ...state, data: [], loading: false, error: payload };
    case ACTIONS.CLEAR_STORAGE:
      if (localAppVersion !== versionHistort[0].version) {
        return { ...state, isLocalClear: true };
      } else {
        return { ...state, isLocalClear: false };
      }

    default:
      return state;
  }
};

const useFetch = (url) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const clearStorage = () => {
    if (localAppVersion) {
      dispatch({ type: ACTIONS.CLEAR_STORAGE });
      localStorage.setItem(
        "appVersion",
        JSON.stringify(versionHistort[0].version)
      );
    } else {
      localStorage.setItem(
        "appVersion",
        JSON.stringify(versionHistort[0].version)
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      clearStorage();
      dispatch({ type: ACTIONS.API_REQUEST });
      await axios
        .get(url)
        .then((res) => {
          dispatch({ type: ACTIONS.FETCH_DATA, payload: res.data });
        })
        .catch((e) => {
          dispatch({ type: ACTIONS.ERROR, payload: e.message });
        });
    };
    fetchData();
  }, [url]);

  return state;
};

export default useFetch;
