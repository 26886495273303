export function isEmpty(value) {
  return value.trim() === "";
}

export function isContatNo(value) {
  return value.length < 7 || value.length > 20;
}

export function isEmail(value) {
  return String(value)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export function isNotPostiveNumber(value) {
  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  return exceptThisSymbols.includes(value);
}

export function stateInfo(storeDetails, country) {
    return storeDetails[0].zones.filter( 
          (({ country_id }) => country_id === country ) 
        )
  }

  export function decodeHTMLEntities(text) {
    var entities = [
        ['amp', '&'],
        ['apos', '\''],
        ['#x27', '\''],
        ['#x2F', '/'],
        ['#39', '\''],
        ['#47', '/'],
        ['lt', '<'],
        ['gt', '>'],
        ['nbsp', ' '],
        ['quot', '"']
    ];

    for (var i = 0, max = entities.length; i < max; ++i)
        text = text.replace(new RegExp('&' + entities[i][0] + ';', 'g'), entities[i][1]);

    return text;
}