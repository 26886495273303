import React, { useContext, useEffect } from "react";
import { IoCloseCircle } from "react-icons/io5";
import { RxPlus } from "react-icons/rx";
import { useLocation } from "react-router-dom";
import CartContext from "../../context/CartContext";
import PopupContext from "../../context/PopupContext";
import { StoreContext } from "../../context/StoreContext";
import BottomPopup from "./BottomPopup";

const TermsPopup = () => {
  const location = useLocation();
  const popupCtx = useContext(PopupContext);
  const cartCtx = useContext(CartContext);
  const { data } = useContext(StoreContext);
  const storeDetails = data.store_master;

  useEffect(() => {
    if (
      popupCtx.isShowTerm &&
      window.location.hash !== popupCtx.openPopupHashId
    ) {
      popupCtx.closeTermPopup();
    }
  }, [location, popupCtx]);
  return (
    <>
      {storeDetails[0].storeterms && (
        <>
          <div
            className={`term-btn ${
              cartCtx.items.length > 0 ? "bottom-up" : ""
            }`}
            onClick={() => popupCtx.openTermPopup()}
          >
            <RxPlus className="term-plus" />
          </div>
          {popupCtx.isShowTerm && (
            <BottomPopup isTermPopup={true}>
              <div className="term-popup">
                <IoCloseCircle
                  className="close-top"
                  onClick={() => popupCtx.closeTermPopup()}
                />
                <h2>Terms and Conditions</h2>
                <div
                  className="detail"
                  dangerouslySetInnerHTML={{
                    __html: storeDetails[0].storeterms,
                  }}
                ></div>
              </div>
            </BottomPopup>
          )}
        </>
      )}
    </>
  );
};

export default TermsPopup;
