import React, { useContext } from "react";
import ProductItem from "./ProductItem";
import { StoreContext } from "../../context/StoreContext";
import SearchContext from "../../context/SearchContext";
import { trimAndLowerCase } from "../../util/trimAndLowerCase";
import {decodeHTMLEntities} from "../../util/validate";

const ProductList = () => {
  const { data } = useContext(StoreContext);
  const searchCtx = useContext(SearchContext);
  // filter by search
  const productsList = data.product_master.filter((value) =>
  decodeHTMLEntities(value.productname)
      .toLowerCase()
      .includes(searchCtx.searchValue.toLowerCase())
  );
  let listOfCategories = productsList.map((item) => decodeHTMLEntities(item.categoryname));

  listOfCategories = listOfCategories
    .sort() // sort and remove duplicates
    .filter((v, i) => listOfCategories.indexOf(v) === i);

  return (
    <div className="productlist-wrap">
      {productsList.length > 0 ? (
        <>
          {listOfCategories.map((cat) => (
            <div
              className="category-wrap"
              key={cat || "others"}
              id={cat ? trimAndLowerCase(cat) : "others"}
            >
              {cat && <span className="c-ttl">{cat}</span>}
              {!cat && <span className="c-ttl">Others</span>}

              {productsList
                .filter((item) => decodeHTMLEntities(item.categoryname) === decodeHTMLEntities(cat))

                .map((item) => (
                  <ProductItem key={item.id} product={item} />
                ))}
            </div>
          ))}
        </>
      ) : (
        <div className="not-found-text">Couldn’t find items</div>
      )}
    </div>
  );
};

export default ProductList;
