import React, { useContext, useEffect } from "react";
import { FaPhone } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { FaUserAlt } from "react-icons/fa";
import UserContext from "../../context/UserContext";
import ContactPopup from "../popup/ContactPopup";
import { StoreContext } from "../../context/StoreContext";
import { useLocation } from "react-router-dom";

const UserInfo = () => {
  const location = useLocation();
  const userCtx = useContext(UserContext);
  const { data } = useContext(StoreContext);
  const storeDetails = data.store_master;
  const popup = userCtx.isContactPopupShow;

  useEffect(() => {
    if (
      userCtx.isContactPopupShow &&
      window.location.hash !== userCtx.openPopupHashId
    ) {
      userCtx.closePopup();
    }
  }, [location, userCtx]);

  return (
    <>
      <div className="user-info">
        <div className="user-list">
          <div className="highlight-box">
            <FaLocationDot />
          </div>
          <span className="phone-number-text">
            <span className="cart-bold-type">Your Location:</span>
            <span className="cart-regular-type">
              {userCtx.userInfo.location}, {userCtx.userInfo.state}, {userCtx.userInfo.country}
            </span>
          </span>
        </div>
        <div className="user-list">
          <div className="highlight-box">
            <FaUserAlt />
          </div>
          <span className="phone-number-text">
            <span className="cart-bold-type">Your Name:</span>
            <span className="cart-regular-type">{userCtx.userInfo.name}</span>
          </span>
        </div>
        <div className="user-list">
          <div className="highlight-box">
            <FaPhone />
          </div>
          <span className="phone-number-text">
            <span className="cart-bold-type">Contact Number:</span>
            <span className="cart-regular-type">
              {userCtx.userInfo.contact}
            </span>
          </span>
          <div className="edit-phone-btn">
            <div
              className="cart-change"
              onClick={() => userCtx.ShowContactPopup()}
              style={{ color: storeDetails[0].primarycolorcode }}
            >
              CHANGE
            </div>
          </div>
        </div>
      </div>

      {popup && <ContactPopup title="Update" />}
    </>
  );
};

export default UserInfo;
