import React, { useContext, useState } from "react";
import { StoreContext } from "../../context/StoreContext";
import UserContext from "../../context/UserContext";
import { isContatNo, isEmpty, isNotPostiveNumber, stateInfo } from "../../util/validate";

const UserForm = ({ title }) => {
  const userCtx = useContext(UserContext);
  const { data } = useContext(StoreContext);
  const storeDetails = data.store_master;
  const [user, setUser] = useState({
    name: userCtx.userInfo.name || "",
    contact: userCtx.userInfo.contact || "",
    email: userCtx.userInfo.email || "",
    country_id: userCtx.userInfo.country_id || "99",
    country: userCtx.userInfo.country || "India",
    state: userCtx.userInfo.state || "",
    state_id: userCtx.userInfo.state_id || "",
  });

  if(user.country_id !== '' && storeDetails[0].countries) {
    var statesInfo = stateInfo(storeDetails, user.country_id);
  }

  const [error, setError] = useState({
    name: false,
    contact: false,
  });
  
  const handleInputChange = (identifier, value, label) => {
    
    if(identifier === "country") {
      setUser((prev) => ({
        ...prev,
        ["country"]: label,
      }));

      setUser((prev) => ({
        ...prev,
        ["country_id"]: value,
      }));

      if(storeDetails[0].countries) {
        setUser((prev) => ({
          ...prev,
          ["state"]: "",
        }));

        setUser((prev) => ({
          ...prev,
          ["state_id"]: "",
        }));
      }

      if(value !== '' && storeDetails[0].countries) {
        statesInfo = stateInfo(storeDetails, value);
      } else {
        statesInfo = [];
      }
      
    } else if(identifier === "state") {
      setUser((prev) => ({
        ...prev,
        ["state"]: label,
      }));

      setUser((prev) => ({
        ...prev,
        ["state_id"]: value,
      }));
    } else {
      setUser((prev) => ({
        ...prev,
        [identifier]: value,
      }));
    }
    
    if(identifier === "contact" && (isContatNo(user.contact))) {
      setError((prev) => ({
        ...prev,
        [identifier]: true,
      }));
    } else if (value === "" || label === "") {
      setError((prev) => ({
        ...prev,
        [identifier]: true,
      }));
    } else {
      setError((prev) => ({
        ...prev,
        [identifier]: false,
      }));
    }
  };

  const handleSubmitUser = (e) => {
    e.preventDefault();
    userCtx.updateUser(user);
  };

  var submitIsEnable = true;

    if(isEmpty(user.name)) {
        submitIsEnable = true;
    } else if(isContatNo(user.contact)) {
        submitIsEnable = true;
    } else if(isEmpty(user.email)) {
        submitIsEnable = true;
    } else if(isEmpty(user.country_id) && storeDetails[0].countries) {
        submitIsEnable = true;
    } else if(isEmpty(user.state_id) && storeDetails[0].zones) {
        submitIsEnable = true;
    } else if(isEmpty(user.country)) {
      submitIsEnable = true;
    } else if(isEmpty(user.state)) {
      submitIsEnable = true;
    } else {
        submitIsEnable = false; 
    }
  
  return (
    <form className="contact-popup ">
      <h2>
        {title} your <br /> Contact Details
      </h2>
      <span className="small-heading">to place order</span>
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          placeholder="Customer Name "
          onChange={(e) => handleInputChange("name", e.target.value, "0")}
          value={user.name}
          style={{ color: storeDetails[0].primarycolorcode }}
        />
        {error.name && <div className="input-error">Enter your name</div>}
      </div>
      <div className="form-group ">
        <input
          type="number"
          className="form-control"
          placeholder="Contact Number"
          onChange={(e) => handleInputChange("contact", e.target.value, "0")}
          onKeyDown={(e) => isNotPostiveNumber(e.key) && e.preventDefault()}
          value={user.contact}
          style={{ color: storeDetails[0].primarycolorcode }}
        />
        {error.contact && (
          <div className="input-error">Enter valid contact number</div>
        )}
      </div>
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          placeholder="Customer Email "
          onChange={(e) => handleInputChange("email", e.target.value, "0")}
          value={user.email}
          style={{ color: storeDetails[0].primarycolorcode }}
        />
        {error.email && <div className="input-error">Enter valid Email ID</div>}
      </div>
      <div className="form-group">
      {(storeDetails[0].countries && <select name="country" className="form-control-sel-opt"
          defaultValue={user.country_id}
          style={{ color: storeDetails[0].primarycolorcode }}
          onChange={(e) => handleInputChange("country", e.target.value, e.target.selectedOptions[0].label)}
        >
          <option value="">--Choose Country--</option>
          {storeDetails[0].countries.map((value, key) => {
            return (
              <option value={value.country_id} key={value.country_id}>
                {value.name}
              </option>
            );
          })}
        </select>) || 
        (<input
          type="text"
          className="form-control"
          placeholder="Country "
          onChange={(e) => handleInputChange("country", "0", e.target.value)}
          value={user.country}
          style={{ color: storeDetails[0].primarycolorcode }}
        />)}
        {(error.country && storeDetails[0].countries && (
          <div className="input-error">Please select country</div>
        )) || (error.country && (
          <div className="input-error">Please add country</div>
        ))}
      </div>
      <div className="form-group">
      {(storeDetails[0].zones && <select name="state" className="form-control-sel-opt"
          defaultValue={user.state_id}
          style={{ color: storeDetails[0].primarycolorcode }}
          onChange={(e) => handleInputChange("state",e.target.value, e.target.selectedOptions[0].label)}
        >
          <option value="">--Choose State--</option>
          {statesInfo && statesInfo.length > 0 &&
            statesInfo.map((value, key) => {
            return (
              <option value={value.zone_id} key={value.zone_id}>
                {value.name}
              </option>
            );
          })}
        </select>) || (<input
          type="text"
          className="form-control"
          placeholder="State "
          onChange={(e) => handleInputChange("state", "0", e.target.value)}
          value={user.state}
          style={{ color: storeDetails[0].primarycolorcode }}
        />)}
        {(error.state && storeDetails[0].zones && (
          <div className="input-error">Please select state</div>
        )) || (error.state && (
          <div className="input-error">Please add state</div>
        ))}
      </div>
      <span className="bottom-txt">{storeDetails[0].contactnote}</span>
      <button
        className="custom-btn"
        disabled={submitIsEnable}
        onClick={(e) => handleSubmitUser(e)}
        style={{ backgroundColor: storeDetails[0].primarycolorcode }}
      >
        Confirm
      </button>
    </form>
  );
};

export default UserForm;
