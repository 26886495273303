import React, { useContext } from "react";
import CartContext from "../../context/CartContext";

const BillDetails = () => {
  const cartCtx = useContext(CartContext);
  const total = cartCtx.items.reduce(
    (acc, cur) => acc + parseFloat(cur.price) * cur.qty,
    0
  );
  const toPay = cartCtx.items.reduce(
    (acc, cur) =>
      acc + parseFloat(cur.specialprice ? cur.specialprice : cur.price) * cur.qty,
    0
  );
  const discount = total - toPay
  return (
    <div className="cart-amount ">
      <div className="cart-price-details">
        <div className="cart-price-heading">Bill details</div>
        <div className="cart-tax">
          <span>Item Total</span>
          <span>&#8377;{total.toFixed(2)}</span>
        </div>
        <div className="cart-tax">
          <span>Discount (-)</span>
          <span>&#8377;{discount.toFixed(2)}</span>
        </div>
        <div className="cart-total-amount">
          <span className="">To Pay</span>
          <span className="total-amount">&#8377;{toPay.toFixed(2)}</span>
        </div>
      </div>
    </div>
  );
};

export default BillDetails;
