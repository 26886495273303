import React, { useContext } from "react";
import PopupContext from "../../context/PopupContext";
import UserContext from "../../context/UserContext";

const BottomPopup = ({
  children,
  requirePopup,
  isOfferPopup,
  isProductPopup,
  isMenuPoup,
  isTermPopup,
}) => {
  const userCtx = useContext(UserContext);
  const popupCtx = useContext(PopupContext);
  const handlePopupClose = () => {
    if (requirePopup) {
      userCtx.closePopup();
    } else if (isOfferPopup) {
      popupCtx.closeOfferPopup();
    } else if (isProductPopup) {
      popupCtx.closeProductPopup();
    } else if (isMenuPoup) {
      popupCtx.closeMenuPopup();
    } else if (isTermPopup) {
      popupCtx.closeTermPopup();
    }
  };
  return (
    <div className="bottom-popup">
      <div className="popup-overlay" onClick={handlePopupClose}></div>
      <div className="popup-body">{children}</div>
    </div>
  );
};

export default BottomPopup;
