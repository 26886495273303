import React, { createContext, useContext, useEffect, useReducer } from "react";
import SearchContext from "./SearchContext";
import { StoreContext } from "./StoreContext";
const openPopupHashId = "#popupOpen";
const orderHasId = "#order";
const localData = JSON.parse(localStorage.getItem("userInfo"));

const UserContext = createContext({
  userInfo: [],
  isContactPopupShow: false,
  isLoactionPopupShow: false,
  isHomePage: true,
  updateUser: (value) => {},
  updateLocation: (value) => {},
  pageRouting: () => {},
  goToOrderPage: () => {},
  goToHomePage: () => {},
  closePopup: () => {},
  ShowLocationPopup: () => {},
  ShowContactPopup: () => {},
  openPopupHashId,
  orderHasId,
});

const initialState = {
  userInfo: localData || [],
  isContactPopupShow: false,
  isLoactionPopupShow: false,
  isHomePage: true,
};

const UserReducer = (state, action) => {
  if (action.type === "UPDATE_USER") {
    const updateInfo = { ...state.userInfo, ...action.value };
    return {
      ...state,
      userInfo: updateInfo,
      isContactPopupShow: false,
    };
  }
  if (action.type === "UPDATE_LOCATION") {
    const updateLoaction = { ...state.userInfo, location: action.value };
    return {
      ...state,
      userInfo: updateLoaction,
      isLoactionPopupShow: false,
    };
  }
  if (action.type === "PAGE_ROUTING") {
    if (!state.userInfo.name && !state.userInfo.contact) {
      return { ...state, isContactPopupShow: !state.isContactPopupShow };
    } else if (!state.userInfo.location) {
      return { ...state, isLoactionPopupShow: !state.isLoactionPopupShow };
    } else {
      return { ...state, isHomePage: false };
    }
  }
  if (action.type === "CLOSE_POPUP") {
    return { ...state, isContactPopupShow: false, isLoactionPopupShow: false };
  }
  if (action.type === "SHOW_LOACTION_POPUP") {
    return { ...state, isLoactionPopupShow: true };
  }
  if (action.type === "SHOW_CONTACT_POPUP") {
    return { ...state, isContactPopupShow: true };
  }
  if (action.type === "GO_ORDER_PAGE") {
    return { ...state, isHomePage: false };
  }
  if (action.type === "GO_HOME_PAGE") {
    return { ...state, isHomePage: true };
  }
  if (action.type === "CLEAR_USER") {
    return { ...state, userInfo: [] };
  }
  return state;
};

export const UserContextProvider = ({ children }) => {
  const [user, dispatchUserAction] = useReducer(UserReducer, initialState);
  const searchCtx = useContext(SearchContext);
  const { isLocalClear } = useContext(StoreContext);

  const updateUser = (value) => {
    dispatchUserAction({ type: "UPDATE_USER", value });
    closePopupFormat();
  };

  const updateLocation = (value) => {
    dispatchUserAction({ type: "UPDATE_LOCATION", value });
    closePopupFormat();
  };

  const pageRouting = () => {
    dispatchUserAction({ type: "PAGE_ROUTING" });
    openPopupFormat();
  };

  const closePopup = () => {
    dispatchUserAction({ type: "CLOSE_POPUP" });
    closePopupFormat();
  };

  const ShowLocationPopup = () => {
    dispatchUserAction({ type: "SHOW_LOACTION_POPUP" });
    openPopupFormat();
  };

  const ShowContactPopup = () => {
    dispatchUserAction({ type: "SHOW_CONTACT_POPUP" });
    openPopupFormat();
  };

  const goToOrderPage = () => {
    dispatchUserAction({ type: "GO_ORDER_PAGE" });
    window.location.hash = orderHasId;
    searchCtx.disableSearch();
  };

  const goToHomePage = () => {
    dispatchUserAction({ type: "GO_HOME_PAGE" });
    searchCtx.updateSearch("");
    if (window.location.hash === orderHasId) {
      window.location.hash = "";
    }
  };

  const openPopupFormat = () => {
    document.body.classList.add("popup-opned");
    if (!searchCtx.isSearchEnabel) {
      window.location.hash = openPopupHashId;
    }
  };

  const closePopupFormat = () => {
    document.body.classList.remove("popup-opned");
    if (window.location.hash === openPopupHashId) {
      window.history.back();
    }
  };

  useEffect(() => {
    if (isLocalClear) {
      dispatchUserAction({ type: "CLEAR_USER" });
    }
  }, [isLocalClear]);

  const userContext = {
    userInfo: user.userInfo,
    isContactPopupShow: user.isContactPopupShow,
    isLoactionPopupShow: user.isLoactionPopupShow,
    isHomePage: user.isHomePage,
    updateUser,
    updateLocation,
    pageRouting,
    goToOrderPage,
    goToHomePage,
    closePopup,
    ShowLocationPopup,
    ShowContactPopup,
    openPopupHashId,
    orderHasId,
  };

  localStorage.setItem("userInfo", JSON.stringify(user.userInfo) || []);

  return (
    <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
  );
};

export default UserContext;
