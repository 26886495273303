import React, { useContext } from "react";
import { StoreContext } from "../../context/StoreContext";

const BannerContent = () => {
  const { data } = useContext(StoreContext);
  const storeDetails = data.store_master;
  return (
    <>
      {storeDetails[0].bannertext && (
        <div className="banner-text-outer">
          <div
            className="banner-text"
            style={{
              backgroundColor: storeDetails[0].primarycolorcode,
            }}
            dangerouslySetInnerHTML={{ __html: storeDetails[0].bannertext }}
          ></div>
        </div>
      )}
    </>
  );
};

export default BannerContent;
