import React, { useContext } from "react";
import { TiLocation } from "react-icons/ti";
import { MdLocalPhone } from "react-icons/md";
import { MdMail } from "react-icons/md";
import { StoreContext } from "../../context/StoreContext";

const Footer = () => {
  const { data } = useContext(StoreContext);
  const storeDetails = data.store_master;
  return (
    <div className="footer">
      <div className="footer-top">
        <div className="foot-store">{storeDetails[0].storename}</div>
        <div className="foot-list-wrap">
          <TiLocation className="foot-icon" />
          <span>{storeDetails[0].storeaddress}</span>
        </div>
        <div className="foot-list-wrap">
          <MdLocalPhone className="foot-icon" />
          <a
            target="_blank"
            href={`tel:${storeDetails[0].storephone}`}
            rel="noreferrer"
          >
            {storeDetails[0].storephone}
          </a>
        </div>
        <div className="foot-list-wrap">
          <MdMail className="foot-icon" />
          <a
            target="_blank"
            href={`mailto:${storeDetails[0].storeemail}`}
            rel="noreferrer"
          >
            {storeDetails[0].storeemail}
          </a>
        </div>
      </div>
      <div className="footer-bottom">
        {storeDetails[0].footertext && <div class="cprt">
          {storeDetails[0].footertext} </div>}
          <div class="cprt">By{" "}
          <a href="http://iamretailer.com/" target="_blank" rel="noreferrer">
            i.am.retailer
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
