import React, { useContext, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import { StoreContext } from "../../context/StoreContext";

const AddButton = ({ qty, add, remove }) => {
  const { data } = useContext(StoreContext);
  const storeDetails = data.store_master;
  const [addAnimate, setAddAnimate] = useState(false);
  const [removeAnimate, setRemoveAnimate] = useState(false);
  const handleAdd = () => {
    add();
    setRemoveAnimate(true);
    setTimeout(() => {
      setRemoveAnimate(false);
    }, 250);
  };

  const handleRemove = () => {
    remove();
    setAddAnimate(true);
    setTimeout(() => {
      setAddAnimate(false);
    }, 250);
  };

  return (
    <>
      {qty === 0 ? (
        <div
          className="btn-div"
          onClick={handleAdd}
          style={{ color: storeDetails[0].primarycolorcode }}
        >
          <span>Add</span>
        </div>
      ) : (
        <div
          className="increment-btn"
          style={{ color: storeDetails[0].primarycolorcode }}
        >
          <div className="add-icon" onClick={handleRemove}>
            <FaMinus />
          </div>
          <div className="p-qty">{qty}</div>
          <div className="add-icon" onClick={handleAdd}>
            <FaPlus />
          </div>
          {addAnimate && (
            <div
              className="add-loader-slider"
              style={{ background: storeDetails[0].primarycolorcode }}
            ></div>
          )}
          {removeAnimate && (
            <div
              className="remove-loader-slider"
              style={{ background: storeDetails[0].primarycolorcode }}
            ></div>
          )}
        </div>
      )}
    </>
  );
};

export default AddButton;
