import React, { createContext, useReducer } from "react";
const openPopupHashId = "#popupOpen";

const PopupContext = createContext({
  isShowOffer: false,
  isShowMenu: false,
  isShowProduct: false,
  isShowTerm: false,
  product: {},
  openOfferPopup: () => {},
  closeOfferPopup: () => {},
  openProductPopup: (item) => {},
  closeProductPopup: () => {},
  openMenuPopup: () => {},
  closeMenuPopup: () => {},
  openTermPopup: () => {},
  closeTermPopup: () => {},
  openPopupHashId,
});

const initialState = {
  isShowOffer: false,
  isShowMenu: false,
  isShowProduct: false,
  isShowTerm: false,
  product: {},
};

const popupReducer = (state, action) => {
  if (action.type === "OPEN_OFFER") {
    return { ...state, isShowOffer: true };
  }
  if (action.type === "CLOSE_OFFER") {
    return { ...state, isShowOffer: false };
  }
  if (action.type === "OPEN_PRODUCT") {
    const item = action.item;
    return { ...state, isShowProduct: true, product: item };
  }
  if (action.type === "CLOSE_PRODUCT") {
    const item = {};
    return { ...state, isShowProduct: false, product: item };
  }
  if (action.type === "OPEN_MENU") {
    return { ...state, isShowMenu: true };
  }
  if (action.type === "CLOSE_MENU") {
    return { ...state, isShowMenu: false };
  }
  if (action.type === "OPEN_TERM") {
    return { ...state, isShowTerm: true };
  }
  if (action.type === "CLOSE_TERM") {
    return { ...state, isShowTerm: false };
  }
  return state;
};

export const PopupContextProvider = ({ children }) => {
  const [popup, dispatchPopupAction] = useReducer(popupReducer, initialState);

  const openOfferPopup = () => {
    dispatchPopupAction({ type: "OPEN_OFFER" });
    openPopupFormat();
  };

  const closeOfferPopup = () => {
    dispatchPopupAction({ type: "CLOSE_OFFER" });
    closePopupFormat();
  };

  const openProductPopup = (item) => {
    dispatchPopupAction({ type: "OPEN_PRODUCT", item });
    openPopupFormat();
  };

  const closeProductPopup = () => {
    dispatchPopupAction({ type: "CLOSE_PRODUCT" });
    closePopupFormat();
  };

  const openMenuPopup = () => {
    dispatchPopupAction({ type: "OPEN_MENU" });
    openPopupFormat();
  };

  const closeMenuPopup = () => {
    dispatchPopupAction({ type: "CLOSE_MENU" });
    closePopupFormat();
  };

  const openTermPopup = () => {
    dispatchPopupAction({ type: "OPEN_TERM" });
    openPopupFormat();
  };

  const closeTermPopup = () => {
    dispatchPopupAction({ type: "CLOSE_TERM" });
    closePopupFormat();
  };

  const openPopupFormat = () => {
    document.body.classList.add("popup-opned");
    window.location.hash = openPopupHashId;
  };

  const closePopupFormat = () => {
    if (window.location.hash === openPopupHashId) {
      window.history.back();
    }
    document.body.classList.remove("popup-opned");
  };

  const popupContext = {
    isShowOffer: popup.isShowOffer,
    isShowMenu: popup.isShowMenu,
    isShowProduct: popup.isShowProduct,
    isShowTerm: popup.isShowTerm,
    product: popup.product,
    openOfferPopup,
    closeOfferPopup,
    openProductPopup,
    closeProductPopup,
    openMenuPopup,
    closeMenuPopup,
    openTermPopup,
    closeTermPopup,
    openPopupHashId,
  };

  return (
    <PopupContext.Provider value={popupContext}>
      {children}
    </PopupContext.Provider>
  );
};

export default PopupContext;
