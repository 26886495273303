import React, { createContext, useReducer } from "react";
const searchHashId = "#search";

const SearchContext = createContext({
  isSearchEnabel: false,
  isScrollTop: false,
  searchValue: "",
  toggleSerch: () => {},
  updateSearch: (value) => {},
  disableSearch: () => {},
  disableScrollTop: () => {},
  searchHashId,
});

const initialState = {
  isSearchEnabel: false,
  searchValue: "",
};

const searchReducer = (state, action) => {
  if (action.type === "SEARCH_TOGGLE") {
    return {
      ...state,
      isSearchEnabel: !state.isSearchEnabel,
      isScrollTop: true,
    };
  }
  if (action.type === "UPDATE_SEARCH") {
    return { ...state, searchValue: action.value };
  }
  if (action.type === "DISABLE_SEARCH") {
    return { ...state, isSearchEnabel: false };
  }
  if (action.type === "DISABLE_SCROLL") {
    return { ...state, isScrollTop: false };
  }
  return state;
};

export const SearchContextProvider = ({ children }) => {
  const [search, dispatchSearchAction] = useReducer(
    searchReducer,
    initialState
  );

  const toggleSerch = () => {
    dispatchSearchAction({ type: "SEARCH_TOGGLE" });
    if (!search.isSearchEnabel) {
      window.location.hash = searchHashId;
    } else {
      window.location.hash = "";
    }
  };

  const updateSearch = (value) => {
    dispatchSearchAction({ type: "UPDATE_SEARCH", value });
  };

  const disableSearch = (value) => {
    dispatchSearchAction({ type: "DISABLE_SEARCH", value });
  };

  const disableScrollTop = () => {
    dispatchSearchAction({ type: "DISABLE_SCROLL" });
  };

  const searchContext = {
    isSearchEnabel: search.isSearchEnabel,
    searchValue: search.searchValue,
    isScrollTop: search.isScrollTop,
    toggleSerch,
    updateSearch,
    disableSearch,
    searchHashId,
    disableScrollTop,
  };
  return (
    <SearchContext.Provider value={searchContext}>
      {children}
    </SearchContext.Provider>
  );
};

export default SearchContext;
