import React, { useContext } from "react";
import { StoreContext } from "../../context/StoreContext";
import { useMatchMedia } from "../../util/useMatchMedia";
import Banner from "./Banner";
import BannerContent from "./BannerContent";
import Location from "./Location";
import Logo from "./Logo";
import Offers from "./Offers";
import SearchBox from "./SearchBox";

const Header = () => {
  const { data } = useContext(StoreContext);
  const storeDetails = data.store_master;
  const isMobile = useMatchMedia("(max-width: 576px)", true);
  return (
    <div className="header">
      {isMobile && <Location />}
      <div
        className="header-box"
        style={{
          backgroundColor: isMobile && storeDetails[0].primarycolorcode,
          borderTopColor: !isMobile && storeDetails[0].primarycolorcode,
        }}
      >
        <div className="header-inner">
          <Logo />
          <SearchBox />
        </div>
        {isMobile && <Banner />}
      </div>
      {isMobile && (
        <>
          <BannerContent />
          <Offers />
        </>
      )}
    </div>
  );
};

export default Header;
