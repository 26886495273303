import React, { useContext, useEffect } from "react";
import { LuSearch } from "react-icons/lu";
import SearchContext from "../../context/SearchContext";

const SearchBox = () => {
  const searchCtx = useContext(SearchContext);

  const showSearch = () => {
    searchCtx.toggleSerch();
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = (e) => {
    const scrollTop = window.scrollY;
    scrollTop >= 250
      ? document.body.classList.add("search-fixed")
      : document.body.classList.remove("search-fixed");
  };
  return (
    <div className="header-search">
      <span onClick={showSearch}>
        <LuSearch className="search-icon" />
        Search for items…
      </span>
    </div>
  );
};

export default SearchBox;
