import React, { useContext } from "react";
import CartContext from "../../context/CartContext";
import ProductItem from "../product/ProductItem";

const CartItem = () => {
  const cartCtx = useContext(CartContext);
  return (
    <div className="cart-item">
      <div className="cart-item-details">
        <div className="cart-item-heading">Items in cart</div>
        <div className="productlist-wrap">
          {cartCtx.items.map((item) => (
            <ProductItem key={item.id} product={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CartItem;
