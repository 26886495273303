import axios from "axios";
import React, { useContext, useState } from "react";
import { API_CHECKOUT_SAVE, API_BUILDER_CHECKOUT_SAVE, STORE_URL } from "../../api/api";
import CartContext from "../../context/CartContext";
import UserContext from "../../context/UserContext";
import {StoreContext} from "../../context/StoreContext";

const ConfirmButton = ({setPopup, setError}) => {
  const cartCtx = useContext(CartContext);
  const userCtx = useContext(UserContext);
  const { data } = useContext(StoreContext);
  const storeDetails = data.store_master;

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const toPay = cartCtx.items.reduce(
    (acc, cur) =>
      acc +
      parseFloat(cur.specialprice ? cur.specialprice : cur.price) * cur.qty,
    0
  );

  const handleConfirmOrderInBuilder = async () => {
    setIsSubmitLoading(true);
    var cartItems = cartCtx.items;
    var newCartItems = [];
    for (var i = 0; i < cartItems.length; i++) {
      var newProduct = {};
      newProduct.product_id = cartItems[i].id;
      newProduct.quantity = cartItems[i].qty;
      newCartItems.push(newProduct);
    }
    
    var customerAddress = {};
    customerAddress.firstname = userCtx.userInfo.name;
    customerAddress.lastname = userCtx.userInfo.name;
    customerAddress.email = userCtx.userInfo.email;
    customerAddress.telephone = userCtx.userInfo.contact;
    customerAddress.phone = userCtx.userInfo.contact;
    customerAddress.city = userCtx.userInfo.location;
    customerAddress.address_2 = "";
    customerAddress.postcode = "postcode";
    customerAddress.address_1 = userCtx.userInfo.location;
    customerAddress.zone_id = userCtx.userInfo.state_id;
    customerAddress.country_id = userCtx.userInfo.country_id;

    var shippingInfo = {};
    shippingInfo.shipping_method = "free.free";
    shippingInfo.comment = "";

    var paymentInfo = {};
    paymentInfo.payment_method = "qr_payment";
    paymentInfo.agree = 1;
    paymentInfo.comment = "";

    const dataFormat = {
      products: newCartItems,
      address: customerAddress,
      shipping: shippingInfo,
      payment: paymentInfo,
      domain: STORE_URL,
    };

    const mycallback = JSON.stringify(dataFormat);
    await axios
      .post(API_BUILDER_CHECKOUT_SAVE, mycallback, {headers: {'Content-Type': 'application/json'}})
      .then(function (response) {
        console.log(response.data.message);
        setPopup(true);
      })
      .catch(function (error) {
        setError({
          status: true,
          message: error.message,
        });
      });
    setIsSubmitLoading(false);
  };

  const handleConfirmOrder = async () => {
    setIsSubmitLoading(true);
    var cartItems = cartCtx.items;
    var newCartItems = [];
    for (var i = 0; i < cartItems.length; i++) {
      var newProduct = {};
      newProduct.product_name = cartItems[i].productname;
      newProduct.price = cartItems[i].specialprice
        ? cartItems[i].specialprice
        : cartItems[i].price;
      newProduct.quantity = cartItems[i].qty;
      newProduct.sku = cartItems[i].code;
      newProduct.total =
        parseFloat(
          cartItems[i].specialprice
            ? cartItems[i].specialprice
            : cartItems[i].price
        ) * cartItems[i].qty.toFixed(2);
      newCartItems.push(newProduct);
    }
    const dataFormat = {
      type: "order",
      customer_fname: userCtx.userInfo.name,
      customer_lname: "",
      customer_phone: userCtx.userInfo.contact,
      customer_email: userCtx.userInfo.email,
      customer_address: userCtx.userInfo.location,
      customer_city: userCtx.userInfo.location,
      customer_state: userCtx.userInfo.state,
      customer_country: userCtx.userInfo.country,
      product: newCartItems,
    };

    const mycallback = JSON.stringify(dataFormat);
    await axios
      .post(API_CHECKOUT_SAVE, mycallback, {headers: {'Content-Type': 'application/json'}})
      .then(function (response) {
        console.log(response.data.message);
        setPopup(true);
      })
      .catch(function (error) {
        setError({
          status: true,
          message: error.message,
        });
      });
    setIsSubmitLoading(false);
  };

  return (
    <button
      className="primary-cta "
      onClick={ (storeDetails[0].internal_domain && handleConfirmOrderInBuilder) || handleConfirmOrder}
      disabled={isSubmitLoading}
    >
      <span className="pay-amount">&#8377;{toPay.toFixed(2)}</span>
      <span className="cta-text">Confirm Order</span>
    </button>
  );
};

export default ConfirmButton;
