import React, { useContext, useEffect } from "react";
import Cart from "../components/cart/Cart";
import Footer from "../components/footer/Footer";
import Menu from "../components/menu/Menu";
import ProductList from "../components/product/ProductList";
import Search from "../components/search/Search";
import Header from "../components/header/Header";
import SearchContext from "../context/SearchContext";
import ProductPopup from "../components/popup/ProductPopup";
import UserContext from "../context/UserContext";
import Banner from "../components/header/Banner";
import Location from "../components/header/Location";
import { useMatchMedia } from "../util/useMatchMedia";
import Offers from "../components/header/Offers";
import LocationPopup from "../components/popup/LocationPopup";
import BannerContent from "../components/header/BannerContent";
import TermsPopup from "../components/popup/TermsPopup";

const HomePage = () => {
  const isMobile = useMatchMedia("(max-width: 576px)", true);
  const searchCtx = useContext(SearchContext);
  const userCtx = useContext(UserContext);

  useEffect(() => {
    if (window.location.hash === userCtx.orderHasId) {
      userCtx.goToOrderPage();
    }
  });
  return (
    <>
      {searchCtx.isSearchEnabel ? (
        <Search />
      ) : (
        <>
          <Header />
          <div className="main-wrap">
            <Menu />
            <div className="right-wrap">
              {!isMobile && (
                <>
                  <Location />
                  <Banner />
                  <div className="banner-text-wrap">
                    <Offers />
                    <BannerContent />
                  </div>
                </>
              )}
              <ProductList isEnablePopup={true} />
              <Footer />
            </div>
          </div>
          <ProductPopup />
          <TermsPopup />
        </>
      )}
      {userCtx.isLoactionPopupShow && <LocationPopup />}
      <Cart />
    </>
  );
};

export default HomePage;
