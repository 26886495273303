import React, { useContext, useEffect } from "react";
import { FiArrowLeft } from "react-icons/fi";
import { IoCloseCircle } from "react-icons/io5";
import SearchContext from "../../context/SearchContext";
import { StoreContext } from "../../context/StoreContext";
import ProductList from "../product/ProductList";
import PopularSearch from "./PopularSearch";
import { useLocation } from "react-router-dom";
import { LuSearch } from "react-icons/lu";

const Search = () => {
  const location = useLocation();
  const { data } = useContext(StoreContext);
  const storeDetails = data.store_master;
  const empty = "";
  const searchCtx = useContext(SearchContext);

  const hideSearch = () => {
    searchCtx.toggleSerch();
    handleSearchChange(empty);
  };

  const handleClear = () => {
    handleSearchChange(empty);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSearchChange = (value) => {
    searchCtx.updateSearch(value);
    if (value === "") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (
      searchCtx.isSearchEnabel &&
      window.location.hash !== searchCtx.searchHashId
    ) {
      searchCtx.toggleSerch();
      handleSearchChange(empty);
    }
  }, [location]);

  useEffect(() => {
    document.body.classList.remove("search-fixed");
    if (searchCtx.isScrollTop) {
      window.scrollTo({
        top: 0,
      });
      searchCtx.disableScrollTop();
    }
  });

  return (
    <div className="search-bar-wrap">
      <div className="search-bar">
        <div className="search-bar-input">
          <div className="icon-box" onClick={hideSearch}>
            <FiArrowLeft className="search-arw" />
            <span>Back to Home</span>
          </div>
          <LuSearch className="search-icon" />
          <input
            type="text"
            onChange={(e) => handleSearchChange(e.target.value)}
            value={searchCtx.searchValue}
            placeholder="Search items"
            autoFocus
          />
          {searchCtx.searchValue && (
            <IoCloseCircle
              className="search-close-icon"
              onClick={handleClear}
            />
          )}
        </div>
      </div>
      <div className="main-wrap main-search-wrap">
        <div className="left-dummy"></div>
        <div className="menu-card">
          {!searchCtx.searchValue && <PopularSearch />}
          <ProductList />
        </div>
      </div>
      <div className="action-container">
        <button
          type="button"
          className="secondary-cta back-btn"
          onClick={hideSearch}
          style={{
            color: storeDetails[0].primarycolorcode,
            borderColor: storeDetails[0].primarycolorcode,
          }}
        >
          BACK
        </button>
      </div>
    </div>
  );
};

export default Search;
