import React, { useContext } from "react";
import { StoreContext } from "../../context/StoreContext";

const QrAndTerm = () => {
  const { data } = useContext(StoreContext);
  const storeDetails = data.store_master;
  return (
    <div className="qr-sec">
      {storeDetails[0].paymentTerms && (
        <div className="qr-detail">
          <div className="cart-price-heading">Payment Terms</div>
          <div className="qr-text">{storeDetails[0].paymentTerms}</div>
        </div>
      )}
      {storeDetails[0].paymentQR && (
        <div className="qr-detail">
          <div className="cart-price-heading">QR Code</div>
          <img src={storeDetails[0].paymentQR} alt="QRcode" />
        </div>
      )}
    </div>
  );
};

export default QrAndTerm;
