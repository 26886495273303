import React from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";

const ErrorAlert = ({ children, message }) => {
  return (
    <div className="error-alert">
      <div className="left-icon">
        <IoIosCloseCircleOutline />
      </div>
      <div className="right-content">
        <div className="text">
          <span>Error</span>
          {message}
        </div>
        {children}
      </div>
    </div>
  );
};

export default ErrorAlert;
