import React, { useContext, useEffect } from "react";
import { FiArrowLeft } from "react-icons/fi";
import { HiMiniArrowLongRight } from "react-icons/hi2";
import PopupContext from "../../context/PopupContext";
import { StoreContext } from "../../context/StoreContext";
import BottomPopup from "../popup/BottomPopup";
import { useLocation } from "react-router-dom";
import offerBg from "../../assets/offer_banner.png";

const Offers = () => {
  const location = useLocation();
  const { data } = useContext(StoreContext);
  const storeDetails = data.store_master;
  const popupCtx = useContext(PopupContext);

  useEffect(() => {
    if (
      popupCtx.isShowOffer &&
      window.location.hash !== popupCtx.openPopupHashId
    ) {
      popupCtx.closeOfferPopup();
    }
  }, [location, popupCtx]);

  return (
    <>
      <>
        {storeDetails[0].offers &&  storeDetails[0].offers.offerdetails && storeDetails[0].offers.offerdetails.length > 0 && (
          <div className="offer-wrap">
            <div className="offer-head">Offers</div>
            <div
              className="offer-banner"
              style={{
                backgroundImage:
                  "url(" +
                  (storeDetails[0].offers.image
                    ? storeDetails[0].offers.image
                    : offerBg) +
                  ")",
              }}
              onClick={() => popupCtx.openOfferPopup()}
            >
              <div className="offer-text">
                <div className="offer-ttl">Exciting offers available</div>
                <div className="offer-view">
                  View offers <HiMiniArrowLongRight className="offer-icon" />
                </div>
              </div>
            </div>
          </div>
        )}
      </>

      {popupCtx.isShowOffer && (
        <BottomPopup isOfferPopup={true}>
          <>
            <div className="offer-popup">
              <div
                className="offer-banner"
                style={{
                  backgroundImage:
                    "url(" +
                    (storeDetails[0].offers.image
                      ? storeDetails[0].offers.image
                      : offerBg) +
                    ")",
                }}
              >
                <FiArrowLeft
                  className="offer-arw"
                  onClick={() => popupCtx.closeOfferPopup()}
                />
                <div className="head">
                  Avail offers while paying from this ordering site
                </div>
              </div>
              <div className="offer-content">
                {storeDetails[0].offers.offerdetails.map((offer, index) => (
                  <div className="offer-content-li" key={index}>
                    <div className="ttl">{offer.title}</div>
                    <div className="desc">{offer.desc}</div>
                  </div>
                ))}
              </div>
            </div>
          </>
        </BottomPopup>
      )}
    </>
  );
};

export default Offers;
