import React, { useContext } from "react";
import { StoreContext } from "../../context/StoreContext";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useMatchMedia } from "../../util/useMatchMedia";

const Banner = () => {
  const isMobile = useMatchMedia("(max-width: 576px)", true);
  const { data } = useContext(StoreContext);
  const storeDetails = data.store_master;
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 2,
    },
  };
  return (
    <>
      {storeDetails[0].banners && storeDetails[0].banners.length > 0 && (
        <>
          {isMobile && (
            <div className="banner-wrapper ">
              <div className="banner-img-container ">
                {storeDetails[0].banners.map((banner, index) => (
                  <div className="banner-img" key={index}>
                    <img src={banner} alt={"banner" + index + 1} />
                  </div>
                ))}
              </div>
            </div>
          )}

          {!isMobile && (
            <div className="banner-slide">
              <Carousel draggable={false} responsive={responsive}>
                {storeDetails[0].banners.map((banner, index) => (
                  <div className="banner-img" key={index}>
                    <img src={banner} alt={"banner" + index + 1} />
                  </div>
                ))}
              </Carousel>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Banner;
