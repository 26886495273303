import React from "react";
import { useContext } from "react";
import { StoreContext } from "../../context/StoreContext";

const Logo = () => {
  const { data } = useContext(StoreContext);
  const storeDetails = data.store_master;
  return (
    <>
      {storeDetails.map((store) => (
        <div className="logo" key={store.id}>
          <div className="logo-wrap">
            <img src={store.logo} alt={store.storename} />
          </div>
          <div className="store-name">
            <span>{store.storename}</span>
          </div>
        </div>
      ))}
    </>
  );
};

export default Logo;
