import React, { useContext } from "react";
import { FaArrowTrendUp } from "react-icons/fa6";
import SearchContext from "../../context/SearchContext";
import { StoreContext } from "../../context/StoreContext";

const PopularSearch = () => {
  const { data } = useContext(StoreContext);
  const storeDetails = data.store_master;
  const searchCtx = useContext(SearchContext);
  const handleSearchUpdate = (value) => {
    searchCtx.updateSearch(value);
  };
  return (
    <>
      {storeDetails[0].popularsearches && (
        <div className="popular-wrap">
          <div className="popular-search">
            <div className="ps-ttl">
              <FaArrowTrendUp className="ps-icon" />
              Popular searches
            </div>
            {storeDetails[0].popularsearches.map((popular, i) => (
              <div
                className="popular-icon-chip"
                key={i}
                onClick={(e) => handleSearchUpdate(popular)}
              >
                {popular}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default PopularSearch;
