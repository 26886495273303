import React, { useContext, useEffect, useState } from "react";
import CartContext from "../../context/CartContext";
import { StoreContext } from "../../context/StoreContext";
import UserContext from "../../context/UserContext";
import ContactPopup from "../popup/ContactPopup";
import { useLocation } from "react-router-dom";
import { useMatchMedia } from "../../util/useMatchMedia";
import { HiMiniShoppingBag } from "react-icons/hi2";
import { IoIosArrowRoundForward, IoIosArrowDropup } from "react-icons/io";
import CartItem from "../order/CartItem";
import SearchContext from "../../context/SearchContext";

const Cart = () => {
  const isTab = useMatchMedia("(max-width: 900px)", true);
  const location = useLocation();
  const cartCtx = useContext(CartContext);
  const userCtx = useContext(UserContext);
  const searchCtx = useContext(SearchContext);
  const { data } = useContext(StoreContext);
  const storeDetails = data.store_master;
  const popup = userCtx.isContactPopupShow;
  const totalItem = cartCtx.items.reduce((acc, cur) => acc + cur.qty, 0);
  const totalAmount = cartCtx.items.reduce(
    (acc, cur) =>
      acc +
      parseFloat(cur.specialprice ? cur.specialprice : cur.price) * cur.qty,
    0
  );
  const [cartPopup, setCartPopup] = useState(false);

  const handleNotMobileClick = () => {
    if (isTab) return;
    handlePageRoute();
  };

  const handleMobileClick = () => {
    if (!isTab) return;
    handlePageRoute();
  };

  const handlePageRoute = () => {
    if (
      userCtx.userInfo.name &&
      userCtx.userInfo.contact &&
      userCtx.userInfo.location
    ) {
      userCtx.goToOrderPage();
    } else {
      userCtx.pageRouting();
    }
  };

  useEffect(() => {
    if (
      !searchCtx.isSearchEnabel &&
      userCtx.isContactPopupShow &&
      window.location.hash !== userCtx.openPopupHashId
    ) {
      userCtx.closePopup();
    }
  }, [location, userCtx]);

  return (
    <>
      {cartCtx.items.length > 0 && (
        <>
          <div className="floating-cart ">
            <div className="bottom-card-row">
              <div
                className="cart-bar"
                style={{
                  backgroundColor: isTab && storeDetails[0].primarycolorcode,
                }}
              >
                <div
                  className="cart-items-bottom-bar"
                  onClick={handleMobileClick}
                >
                  <div className="cart-shopping-bag">
                    {cartPopup && <CartItem />}
                    <div className="shopping-bag-icon-wrap">
                      <HiMiniShoppingBag className="cart-icon" />

                      <span className="total-items">{totalItem}</span>
                    </div>
                    <span className="cart-amt">₹{totalAmount.toFixed(2)}</span>
                    {!isTab && (
                      <IoIosArrowDropup
                        className={`cart-arw ${cartPopup ? "rotate" : ""}`}
                        onClick={() => setCartPopup(!cartPopup)}
                      />
                    )}
                  </div>
                  <div
                    className="cart-bottom-button"
                    onClick={handleNotMobileClick}
                    style={{
                      backgroundColor:
                        !isTab && storeDetails[0].primarycolorcode,
                    }}
                  >
                    <span>VIEW CART</span>
                    <IoIosArrowRoundForward className="cart-arrow-icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {popup && <ContactPopup title="Confirm" />}
        </>
      )}
    </>
  );
};

export default Cart;
